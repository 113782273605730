import { Card, Divider, Grid, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chart from "react-apexcharts";
import { MeanAnswer } from './mean-answer';
import { ExportAuditStats } from "./audit-export-stats";

export const AuditsGraphics = (props) => {
  const { result } = props;
  const theme = useTheme();
  const items = [
    {
      color: '#4CAF50',
      label: 'Very good',
      subtitle: 'Terminé',
      percent: result.termine_percent,
      value: result.termine,
    },
    {
      color: '#FF9800',
      label: 'Good',
      subtitle: 'En cours',
      percent: result.en_cours_percent,
      value: result.en_cours
    },
    {
      color: '#F44336',
      label: 'Needs attention ',
      subtitle: 'Pas commencé',
      percent: result.pas_commence_percent,
      value: result.pas_commence
    }
  ];

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    fill: {
      opacity: 1
    },
    labels: ['Apprenants'],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            color: theme.palette.text.secondary,
            fontSize: '12px',
            fontWeight: 400,
            offsetY: 20
          },
          value: {
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600,
            offsetY: -20
          }
        },
        hollow: {
          size: '60%'
        },
        track: {
          background: theme.palette.background.default
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  return (
    <Card {...props}>
      <Divider />
      <Grid
        container
        justifyContent={'flex-end'}
        paddingRight={3}
        paddingTop={2}
      >
        <ExportAuditStats auditId={result.audit_id} session={result} global={"result"}/>
      </Grid>
      <Grid
        container
        justifyContent={'flex-end'}
        marginTop={3}
      >
        <Typography
          fontSize={20}
        >
          Début de session :
        </Typography>
        <Typography
          fontSize={20}
          sx={{
            ml:0.5,
            mr: 2
          }}
          fontWeight={"bold"}
        >
          {result.start_session}
        </Typography>
        <Typography
          fontSize={20}
        >
          Fin de session :
        </Typography>
        <Typography
          fontSize={20}
          sx={{
            ml:0.5,
            mr:4
          }}
          fontWeight={"bold"}
        >
          {result.end_session}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent={'flex-end'}
        marginTop={2}
      >
        <Typography
          fontSize={20}
        >
          Temps moyen passé par session :&nbsp;
        </Typography>
        <Typography
          fontSize={20}
          fontWeight={"bold"}
          sx={{
            mr:4
          }}
        >
          {result.time_spent}
        </Typography>
      </Grid>
      <Typography
            variant="h6"
            sx={{ m: 2}}
          >
            Nombre d'apprenant(s) dans la session : {result.total}
          </Typography>
      <Grid
        container
        spacing={3}
        sx={{ p: 3 }}
      >
        {items.map((item) => (
          <Grid
            item
            key={item.subtitle}
            md={4}
            xs={12}
          >
            <Card
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
              variant="outlined"
            >
              <Typography
                sx={{ color: item.color }}
                variant="h6"
              >
                {item.subtitle}
              </Typography>
              <Chart
                height={200}
                options={{
                  ...chartOptions,
                  colors: [item.color]
                }}
                series={[item.percent]}
                type="radialBar"
              />
              <Typography variant="h6">
                {item.value}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {item.subtitle}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        // display={'flex'}
        padding={2}
      >
        <Grid
          marginLeft={11}
          width={'40%'}
        >
          <MeanAnswer
            answer={result.moyenne_1}
            tour={"premier"}
            />
        </Grid>
        <Grid
          marginLeft={15}
          width={'40%'}
        >
          <MeanAnswer
            answer={result.moyenne_2}
            tour={"second"}
            />
        </Grid>
      </Grid>
    </Card>
  );
};