import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';

export const VignetteStats = (props) => {
  const { result, global, turn } = props;
  const theme = useTheme();
  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      }
    },
    colors: ['#13affe', '#fbab49'],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    legend: {
      labels: {
        colors: theme.palette.text.secondary
      },
      show: true
    },
    plotOptions: {
      bar: {
        columnWidth: '100%',
        horizontal: true,
      }
    },
    stroke: {
      colors: ['transparent'],
      show: true,
      width: 3,
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        show: true,
        color: theme.palette.divider
      },
      axisTicks: {
        show: true,
        color: theme.palette.divider
      },
      categories: result.map((data) => ("Mini vignette n°" + data.number)),
      labels: {
        style: {
          fontSize: '12px',
          colors: theme.palette.text.secondary
        }
      },
      title: {
        text: '% de bonnes réponses'
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      labels: {
        style: {
          fontSize: '14px',
          colors: theme.palette.text.secondary
        }
      },
    },
  };

  const chartSeries = [
    {
      data: result.map((data) => (data.mean)),
      name: 'Moyenne de la session'
    },
    {
      data: global.map((data) => (data.mean)),
      name: 'Moyenne de toutes les sessions'
    },
  ];
  return (
    <Card>
      <Grid
        marginLeft={20}
      >
        <Typography
          display={'inline'}
          fontSize={18}
        >
          Moyenne de réponse du&nbsp;
        </Typography>
        {turn == 1?
          <Typography
            display={'inline'}
            fontWeight={'bold'}
            fontSize={18}
          >
            premier
          </Typography>
          :
          <Typography
            display={'inline'}
            fontWeight={'bold'}
            fontSize={18}
          >
            second
          </Typography>
        }
        <Typography
          display={'inline'}
          fontSize={18}
        >
          &nbsp;tour
        </Typography>
      </Grid>
      <CardContent>
        <Chart
          height={500}
          options={chartOptions}
          series={chartSeries}
          type="bar"
        />
      </CardContent>
    </Card>
  );
};
