import { Avatar, Box, CircularProgress, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import { coursesApi } from '../../../api/course-api';
import { useMounted } from '../../../hooks/use-mounted';
import { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/use-auth';

export const CustomerResponseTCS = (props) => {
  const { open, handleClose, course_id } = props;
  const isMounted = useMounted();
  const [resultTcs, setResultTcs] = useState(null)
  const [collapseStatesFirstTurn, setCollapseStatesFirstTurn] = useState([]);
  const [collapseStatesSecondTurn, setCollapseStatesSecondTurn] = useState([]);
  const { user } = useAuth(); 

  const getAnswer = useCallback(async () => {
    try {
      const data = await coursesApi.getTcsResult(user.admin_id, course_id)
      if (isMounted()) {
        setResultTcs(data)
        setCollapseStatesFirstTurn(new Array(data.tour_1.mini_vignette.length).fill(false));
        setCollapseStatesSecondTurn(new Array(data.tour_2.mini_vignette.length).fill(false));
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const handleCollapseToggleFirstTurn = (index) => {
    setCollapseStatesFirstTurn((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  const handleCollapseToggleSecondTurn = (index) => {
    setCollapseStatesSecondTurn((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  useEffect(() => {
    getAnswer();
  }, []);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
            variant="h2"
            align='center'
        >
          Liste des réponses
        </DialogTitle>
        {resultTcs ?
          <DialogContent>
            <DialogContentText>
              <Grid>
                <Typography
                  variant="h3"
                  color="secondary"
                >
                  Tour 1 :
                </Typography>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={'0.3em'}>
                <Grid item>
                  <Typography sx={{fontSize:20, fontWeight:'bold'}}>
                    Score de l'apprenant {resultTcs.tour_1.correct_answers} / {resultTcs.tour_1.total_questions}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{fontSize:18, fontStyle:'italic', mt: 0.3}}>
                    ({resultTcs.tour_1.percentage}%)
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ mt: '1em' }}>
                {collapseStatesFirstTurn.length > 0 &&
                  resultTcs.tour_1.mini_vignette.map((mini_vignette, index) => (
                    <Grid
                      key={index}
                      container
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{
                        p: 1,
                        backgroundColor: index % 2 === 0 ? 'lightgrey' : 'white',
                      }}
                    >
                      <Grid item>
                        <Typography sx={{fontWeight: 'bold', fontSize: 22}} onClick={() => handleCollapseToggleFirstTurn(index)}>
                          Mini vignette {index + 1}
                        </Typography>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center'}}>
                          {mini_vignette.score} / {mini_vignette.nb_answer}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Collapse in={collapseStatesFirstTurn[index]} timeout="auto" unmountOnExit sx={{mt: 2}}>
                          {mini_vignette.result.map((vignette, indexVignette) => (
                            <Grid container justifyContent="center" alignItems="center" key={indexVignette}>
                              <Typography sx={{mb: 0.7, mr: 2, fontSize: 20}}>
                                {indexVignette+1}.
                              </Typography>
                              {[-2, -1, 0, 1, 2].map((value, indexResult) => (
                                <Avatar
                                  key={indexResult}
                                  sx={{
                                    mx: 1,
                                    mb: 2,
                                    width: '3em',
                                    height: '3em',
                                    backgroundColor:
                                      value === vignette.good_answer ? 'green' : value === vignette.answer ? 'red' : 'transparent',
                                  }}
                                >
                                  <Typography
                                    sx={{fontSize: 20, fontWeight: 'bold', color: 'black'}}
                                  >
                                    {value}
                                  </Typography>
                                </Avatar>
                              ))}
                            </Grid>
                          ))}
                        </Collapse>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid marginBottom={1} marginTop={3}>
                <Typography variant="h5">
                  Interprétation des résultats
                </Typography>
              </Grid>
              <Grid
                marginLeft={4}
              >
                {resultTcs.tour_1.notes.amelio || resultTcs.tour_1.notes.positive || resultTcs.tour_1.notes.why ?
                  <Grid>
                    <Grid container justify="space-between">  
                      <Typography inline fontWeight={"bold"} align="left">
                        Points forts :&nbsp;
                      </Typography>
                      <Typography inline variant="body1" align="right">
                        {resultTcs.tour_1.notes.positive}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">  
                      <Typography inline fontWeight={"bold"} align="left">
                        Points à améliorer :&nbsp;
                      </Typography>
                      <Typography inline variant="body1" align="right">
                        {resultTcs.tour_1.notes.amelio}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">  
                      <Typography inline fontWeight={"bold"} align="left">
                        Pourquoi :&nbsp;
                      </Typography>
                      <Typography inline variant="body1" align="right">
                        {resultTcs.tour_1.notes.why}
                      </Typography>
                    </Grid>
                  </Grid>
                : 
                  "Aucune interprétation des résultats"
                }
              </Grid>
              <Grid marginTop={4} marginBottom={1}>
                <Typography variant="h5">
                  Objectifs d'améliorations
                </Typography>
              </Grid>
              <Grid marginLeft={4} marginBottom={4}>
                {resultTcs.tour_1.improvements ? resultTcs.tour_1.improvements.map((improvement, index) => (
                  <Grid key={index}>
                    <Grid>
                      <Typography variant="h6" marginTop={1} marginBottom={0.5} color="#1155EE">
                        Objectif {index + 1} :
                      </Typography>
                    </Grid>
                    <Grid marginLeft={4}>
                      <Grid container justify="space-between">  
                        <Typography inline fontWeight={"bold"} align="left">
                          Commentaire :&nbsp;
                        </Typography>
                        <Typography inline variant="body1" align="right">
                          {improvement.comment}
                        </Typography>
                      </Grid>
                      <Grid container justify="space-between">  
                        <Typography inline fontWeight={"bold"} align="left">
                          Objectif :&nbsp;
                        </Typography>
                        <Typography inline variant="body1" align="right">
                          {improvement.objective}
                        </Typography>
                      </Grid>
                      <Grid container justify="space-between">  
                        <Typography inline fontWeight={"bold"} align="left">
                          Point à améliorer :&nbsp;
                        </Typography>
                        <Typography inline variant="body1" align="right">
                          {improvement.point}
                        </Typography>
                      </Grid>
                      <Grid container justify="space-between">
                        <Typography inline fontWeight={"bold"} align="left">
                          Stratégie :&nbsp;
                        </Typography>
                        <Typography inline variant="body1" align="right">
                          {improvement.strategy}
                        </Typography>
                      </Grid>
                      <Grid container justify="space-between">  
                        <Typography inline fontWeight={"bold"} align="left">
                          Date limite :&nbsp;
                        </Typography>
                        <Typography inline variant="body1" align="right">
                          {improvement.limit_date}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
                :
                  "Aucune amélioration" 
                }
              </Grid>
              <Grid sx={{mt: 6}}>
                <Typography
                  variant="h3"
                  color="secondary"
                >
                  Tour 2 :
                </Typography>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={'0.3em'}>
                <Grid item>
                  <Typography sx={{fontSize:20, fontWeight:'bold'}}>
                    Score de l'apprenant {resultTcs.tour_2.correct_answers} / {resultTcs.tour_2.total_questions}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{fontSize:18, fontStyle:'italic', mt: 0.3}}>
                    ({resultTcs.tour_2.percentage}%)
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ mt: '1em' }}>
                {collapseStatesSecondTurn.length > 0 &&
                  resultTcs.tour_2.mini_vignette.map((mini_vignette, index) => (
                    <Grid
                      key={index}
                      container
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{
                        p: 1,
                        backgroundColor: index % 2 === 0 ? 'lightgrey' : 'white',
                      }}
                    >
                      <Grid item>
                        <Typography sx={{fontWeight: 'bold', fontSize: 22}} onClick={() => handleCollapseToggleSecondTurn(index)}>
                          Mini vignette {index + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Collapse in={collapseStatesSecondTurn[index]} timeout="auto" unmountOnExit>
                          <Typography sx={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                            {mini_vignette.score} / {mini_vignette.nb_answer}
                          </Typography>
                          {mini_vignette.result.map((vignette, indexVignette) => (
                            <Grid container justifyContent="center" alignItems="center" key={indexVignette}>
                              <Typography sx={{mb: 0.7}}>
                                {indexVignette+1}.
                              </Typography>
                              {[-2, -1, 0, 1, 2].map((value, indexResult) => (
                                <Typography
                                  key={indexResult}
                                  sx={{ mx: 6, mb: 1, textAlign: 'center', fontSize: 20, fontWeight: 'bold',
                                    color: value === vignette.good_answer ? "green" : value === vignette.answer ? "red" : "grey"
                                  }}
                                >
                                  {value}
                                </Typography>
                              ))}
                            </Grid>
                          ))}
                        </Collapse>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid marginBottom={1} marginTop={3}>
                <Typography variant="h5">
                  Interprétation des résultats
                </Typography>
              </Grid>
              <Grid
                marginLeft={4}
              >
                {resultTcs.tour_2.notes.amelio || resultTcs.tour_2.notes.positive || resultTcs.tour_2.notes.why ?
                  <Grid>
                    <Grid container justify="space-between">  
                      <Typography inline fontWeight={"bold"} align="left">
                        Points forts :&nbsp;
                      </Typography>
                      <Typography inline variant="body1" align="right">
                        {resultTcs.tour_2.notes.positive}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">  
                      <Typography inline fontWeight={"bold"} align="left">
                        Points à améliorer :&nbsp;
                      </Typography>
                      <Typography inline variant="body1" align="right">
                        {resultTcs.tour_2.notes.amelio}
                      </Typography>
                    </Grid>
                    <Grid container justify="space-between">  
                      <Typography inline fontWeight={"bold"} align="left">
                        Pourquoi :&nbsp;
                      </Typography>
                      <Typography inline variant="body1" align="right">
                        {resultTcs.tour_2.notes.why}
                      </Typography>
                    </Grid>
                  </Grid>
                : 
                  "Aucune interprétation des résultats"
                }
              </Grid>
            </DialogContentText>
          </DialogContent>
        :
          <Box
            display= "flex"
            justifyContent= "center"
            alignItems= "center"
            padding={5}
          >
            <CircularProgress
              color="inherit"
              size={100}
            />
          </Box>
        }
        </Dialog>
    </>
  );
};