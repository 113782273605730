import { useRef, useState } from 'react';
import { ListItemIcon, ListItemText, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import * as XLSX from 'xlsx-js-style';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../hooks/use-auth';
import { statsApi } from '../../../api/stat-api';

export const ExportAuditStats = (props) => {
  const { auditId, session, global } = props;
  const anchorRef = useRef(null);
  const [openExport, setOpenExport] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth(); 

  const handleExportOpen = () => {
    setOpenExport(true);
  };

  const handleMenuClose = () => {
    setOpenExport(false);
  };

  const statsFirstTurn = (tableExcel, userStats) => {
    // 1er tour
    tableExcel.push(['', 'Premier tour']);
    tableExcel.push(['']);
    tableExcel.push(['', 'Oui', 'Non', 'Pnc'])
    
    // Moyenne de la session
    tableExcel.push(['Moyenne de la session', session.moyenne_1.oui, session.moyenne_1.non, session.moyenne_1.pnc])
    const globalStats = userStats.criterias.global[0].pop();
    userStats.criterias.session[0].pop();
    tableExcel.push(['Moyenne de toutes les sessions', globalStats.oui, globalStats.non, globalStats.pnc])

    // Ajout des moyennes des apprenants
    userStats.users[0].forEach((user) => {
      if (user.result[0] != 0 || user.result[1] != 0 || user.result[2] != 0) {
        tableExcel.push([user.name, user.result[0], user.result[1], user.result[2]])
      }
    })

    // Titrage des notes
    tableExcel.push([''])
    tableExcel.push(['', 'Liste des interprétations des résultats'])
    tableExcel.push([''])
    tableExcel.push(['Nom de l\'apprenant', 'Points forts', 'Points à améliorer', 'Pourquoi'])

    // Ajout des notes
    userStats.users[0].forEach((user) => {
      if (user.notes.postive || user.notes.amelio || user.notes.why)
        tableExcel.push([user.name, user.notes.postive, user.notes.amelio, user.notes.why])
    })

    // Titrage des improvements
    tableExcel.push([''])
    tableExcel.push(['', 'Liste des améliorations'])
    tableExcel.push([''])
    tableExcel.push(['Nom de l\'apprenant', 'Point à améliorer', 'Objectif d\'amélioration', 'Stratégie', 'Date limite', 'Commentaire'])

    // Ajout des objectifs d'amélioration
    userStats.users[0].forEach((user) => {
      user.improvements.forEach((improv) => {
        if (improv.point || improv.objective || improv.strategy || improv.limi_date || improv.comment) {
          tableExcel.push([user.name, improv.point, improv.objective, improv.strategy, improv.limi_date, improv.comment])
        }
      })
    })

    return tableExcel;
  }

  const statsSecondTurn = (tableExcel, userStats) => {

    // 2eme tour
    tableExcel.push(['']);
    tableExcel.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
    tableExcel.push(['']);
    tableExcel.push(['', 'Second tour']);
    tableExcel.push(['']);
    tableExcel.push(['', 'Oui', 'Non', 'Pnc'])
    
    // Moyenne de la session
    tableExcel.push(['Moyenne de la session', session.moyenne_2.oui, session.moyenne_2.non, session.moyenne_2.pnc])
    const globalStats = userStats.criterias.global[1].pop();
    userStats.criterias.session[1].pop();
    tableExcel.push(['Moyenne de toutes les sessions', globalStats.oui, globalStats.non, globalStats.pnc])

    // Ajout des moyennes des apprenants
    userStats.users[1].forEach((user) => {
      if (user.result[0] != 0 || user.result[1] != 0 || user.result[2] != 0) {
        tableExcel.push([user.name, user.result[0], user.result[1], user.result[2]])
      }
    })

    // Titrage des notes
    tableExcel.push([''])
    tableExcel.push(['', 'Liste des interprétations des résultats'])
    tableExcel.push([''])
    tableExcel.push(['Nom de l\'apprenant', 'Points forts', 'Points à améliorer', 'Pourquoi'])

    // Ajout des notes
    userStats.users[1].forEach((user) => {
      if (user.notes.postive || user.notes.amelio || user.notes.why)
        tableExcel.push([user.name, user.notes.postive, user.notes.amelio, user.notes.why])
    })
    return tableExcel;
  }

  const statsByCriteriaFirstTurn = (tableExcel, userStats) => {
    tableExcel[0] = tableExcel[0].concat(['', '', '', '', '', '', 'Moyenne par critère du premier tour de la session', '', '', '', 'Moyenne par critère du premier tour de toutes les sessions']);
    tableExcel[2] = tableExcel[2].concat(['', '', '', '', 'Oui', 'Non', 'Pnc', '', '', 'Oui', 'Non', 'Pnc']);
    let index = 3;

    userStats.criterias.session[0].forEach((criteria, indexCriteria) => {
      if (index < tableExcel.length) {
        let shift_needed = 7 - tableExcel[index].length;
        while (shift_needed > 0) {
          tableExcel[index] = tableExcel[index].concat(['']);
          shift_needed -= 1;
        }
        tableExcel[index] = tableExcel[index].concat(['Critère n°' + criteria.number, criteria.oui, criteria.non, criteria.pnc, '',
        'Critère n°' + userStats.criterias.global[0][indexCriteria].number, userStats.criterias.global[0][indexCriteria].oui, userStats.criterias.global[0][indexCriteria].non,
        userStats.criterias.global[0][indexCriteria].pnc])
      } 
      else {
        tableExcel.push(['', '', '', '', '', '', '', 'Critère n°' + criteria.number, criteria.oui, criteria.non, criteria.pnc, '',
        'Critère n°' + userStats.criterias.global[0][indexCriteria].number, userStats.criterias.global[0][indexCriteria].oui, userStats.criterias.global[0][indexCriteria].non,
        userStats.criterias.global[0][indexCriteria].pnc])
      }
      index += 1
    })

    return tableExcel
  }

  const statsByCriteriaSecondTurn = (tableExcel, userStats) => {
    let index = tableExcel.findIndex(item => {
      if (item.length >= ["", "Second tour"].length) {
        for (let i = 0; i < ["", "Second tour"].length; i++) {
          if (["", "Second tour"][i] !== item[i]) {
            return false;
          }
        }
        return true;
      }
      return false;
    });

    tableExcel[index] = tableExcel[index].concat(['', '', '', '', '', '', 'Moyenne par critère du second tour de la session', '', '', '', 'Moyenne par critère du second tour de toutes les sessions']);
    index += 2;
    tableExcel[index] = tableExcel[index].concat(['', '', '', '', 'Oui', 'Non', 'Pnc', '', '', 'Oui', 'Non', 'Pnc']);
    index += 1;

    userStats.criterias.session[1].forEach((criteria, indexCriteria) => {
      if (index < tableExcel.length) {
        let shift_needed = 7 - tableExcel[index].length;
        while (shift_needed > 0) {
          tableExcel[index] = tableExcel[index].concat(['']);
          shift_needed -= 1;
        }
        tableExcel[index] = tableExcel[index].concat(['Critère n°' + criteria.number, criteria.oui, criteria.non, criteria.pnc, '',
        'Critère n°' + userStats.criterias.global[1][indexCriteria].number, userStats.criterias.global[1][indexCriteria].oui, userStats.criterias.global[1][indexCriteria].non,
        userStats.criterias.global[1][indexCriteria].pnc])
      } 
      else {
        tableExcel.push(['', '', '', '', '', '', '', 'Critère n°' + criteria.number, criteria.oui, criteria.non, criteria.pnc, '',
        'Critère n°' + userStats.criterias.global[1][indexCriteria].number, userStats.criterias.global[1][indexCriteria].oui, userStats.criterias.global[1][indexCriteria].non,
        userStats.criterias.global[1][indexCriteria].pnc])
      }
      index += 1
    })

    return tableExcel
  }

  function makeCellsBold(worksheet, keywords) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
  
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellAddress];
  
        if (cell && cell.v && keywords.includes(cell.v)) {
          if (!cell.s) {
            cell.s = {};
          }
          cell.s.font = { bold: true };
        }
      }
    }
  }

  function makeCellsBlack(worksheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
  
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellAddress];
  
        if (cell && cell.v && ["Oui", "Non", "Pnc"].includes(cell.v)) {
          if (!cell.s) {
            cell.s = {};
          }
          cell.s.fill = { fgColor: { rgb: '000000'} } ;
          cell.s.font = { bold: true, color: { rgb: 'FFFFFF' } };
          cell.s.width = 30;
        }
      }
    }
  }

  function colorBeforeSecondTurn(worksheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    let targetRow = -1; // Indice de la ligne cible
    
    for (let row = range.s.r + 1; row <= range.e.r; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: 1 }); // Colonne B (index 1)
      const cell = worksheet[cellAddress];
    
      if (cell && cell.v === "Second tour") {
        targetRow = row - 2; // Récupérer la ligne précédente
        break; // Sortir de la boucle après avoir trouvé la ligne
      }
    }
    if (targetRow >= range.s.r) {
      // Appliquer la couleur de remplissage jaune à la ligne cible
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: targetRow, c: col });
        const cell = worksheet[cellAddress];
        if (!cell) {
          break;
        }
        if (cell && !cell.s) {
          cell.s = {};
        }
        cell.s.fill = { fgColor: { rgb: 'FFFF00' } }; // Couleur de remplissage jaune
      }
    }
  }

  const handleExportStats = async () => {
    try {
      setLoading(true);
      setOpenExport(false);
      const userStats = await statsApi.getAuditStatsForAllUsersBySession(user.admin_id, auditId, session.start_session, session.end_session)

      let tableExcel = [];
      tableExcel = statsFirstTurn(tableExcel, userStats);
      tableExcel = statsByCriteriaFirstTurn(tableExcel, userStats);

      tableExcel = statsSecondTurn(tableExcel, userStats);
      tableExcel = statsByCriteriaSecondTurn(tableExcel, userStats);

      const worksheet = XLSX.utils.aoa_to_sheet(tableExcel);

      const keywordsBold = ['Premier tour', 'Second tour', 'Liste des améliorations', 'Liste des interprétations des résultats', 'Moyenne de la session', 'Moyenne de toutes les sessions',
      'Nom de l\'apprenant', 'Point à améliorer', 'Objectif d\'amélioration', 'Stratégie', 'Date limite', 'Commentaire', 'Moyenne par critère du premier tour de la session',
      'Moyenne par critère du second tour de la session', 'Points forts', 'Points à améliorer', 'Pourquoi', 'Moyenne par critère du premier tour de toutes les sessions',
      'Moyenne par critère du second tour de toutes les sessions']
      makeCellsBold(worksheet, keywordsBold)
      makeCellsBlack(worksheet)
      colorBeforeSecondTurn(worksheet)

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Statistiques');
      XLSX.writeFile(wb, `audit_${auditId}_session_${session.start_session}_${session.end_session}.xlsx`);
      setLoading(false);
    } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de l\'export des statistiques !');
        setLoading(false);
        setOpenExport(false);
      }
  }

  return (
    <>
      {loading ?
        <CircularProgress size={28} sx={{mb: 1, mr: 1}}/>
      :
      <>
        <Tooltip title="Export des stats">
          <IconButton
            onClick={handleExportOpen}
            ref={anchorRef}
            {...props}>
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
          onClose={handleMenuClose}
          open={openExport}
          PaperProps={{
            sx: {
              maxWidth: '100%',
              width: 256
            }
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
        >
          {loading?
            <CircularProgress />
          :
          <MenuItem onClick={handleExportStats}>
            <ListItemIcon>
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Exporter les statistiques"
            />
          </MenuItem>
          }
        </Menu>
      </>
    }
    </>
  );
};
