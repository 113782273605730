import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Checkbox, Container, Divider, FormControlLabel, Grid, InputAdornment, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import SearchIcon from '@mui/icons-material/Search';

import { userApi } from '../../../api/user-api';
import { SeverityPill } from '../../../components/severity-pill';
import { ArrowDropDown } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

const Activities = () => {
  const { user } = useAuth(); 

  const isMounted = useMounted();
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [pagin, setPagin] = useState(0);
  const [limit, setLimit] = useState(25);
  const [selectedType, setSelectedType] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [startDate, setStartDate] = useState("01/01/2020");
  const [endDate, setEndDate] = useState(dayjs().format('DD/MM/YYYY'))
  const [sortOrder, setSortOrder] = useState("desc");
  const [courseOnly, setCourseOnly] = useState(false);

  const types = [...new Set(logs.map((log) => log.type))];

  dayjs.locale('fr');
  
  const getLogs = useCallback(async() => {
    try {
      const data = await userApi.getAllActivities(user.admin_id);
      if (isMounted()) {
        setLogs(data);
        setFilteredLogs(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted])

  const resetFilters = () => {
    setSelectedType(null);
    setSearchName("");
    setStartDate("01/01/2008");
    setEndDate(dayjs().format('DD/MM/YYYY'));
    setSortOrder("desc");
    setCourseOnly(false);
  };

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
  }

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));

  }
  const handlePageChange = (event, newPage) => {
    setPagin(newPage)
  }
  const applyPagination = (fLogs, pagin, limit) => fLogs.slice(pagin * limit, pagin * limit + limit);

  const paginatedLogs = applyPagination(filteredLogs, pagin, limit);

  const sortLogs = (logs) => {
    return logs.sort((a, b) => {
      const dateA = dayjs(a.created_at, "DD/MM/YYYY");
      const dateB = dayjs(b.created_at, "DD/MM/YYYY");
  
      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  };

  useEffect(() => {
    let tempLog = logs.filter((el) => `${el.name}`.toLowerCase().includes(searchName.toLowerCase()) ||
    `${el.description}`.toLowerCase().includes(searchName.toLowerCase()))

    if (startDate) {
      const start = dayjs(startDate, 'DD/MM/YYYY');
      tempLog = tempLog.filter((log) => dayjs(log.created_at, 'DD/MM/YYYY').isSame(start, 'day') || dayjs(log.created_at, 'DD/MM/YYYY').isAfter(start));
    }

    if (endDate) {
      const end = dayjs(endDate, 'DD/MM/YYYY');
      tempLog = tempLog.filter((log) =>
        dayjs(log.created_at, 'DD/MM/YYYY').isSame(end, 'day') ||
        dayjs(log.created_at, 'DD/MM/YYYY').isBefore(end)
      );
    }
    
    tempLog = sortLogs(tempLog);

    if (selectedType !== null) {
      tempLog = tempLog.filter((log) => log.type === selectedType);
    }

    if (courseOnly) {
      tempLog = tempLog.filter((log) => log.courses_id !== null);
    }
    
    setFilteredLogs(tempLog);
    setPagin(0);
  }, [sortOrder, startDate, endDate, searchName, selectedType, logs, courseOnly]);

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4">
                  Activités
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Divider/>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                m: -1,
                p: 3
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: '100%',
                  width: '25em'
                }}
              >
                <TextField
                  value={searchName}
                  onChange={handleChangeSearch}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Chercher un apprenant ou une description"
                />
              </Box>
              <Select
                value={selectedType}
                displayEmpty="true"
                onChange={(event) => setSelectedType(event.target.value)}
                sx={{
                  minWidth: '10em',
                  maxHeight: '3.5em',
                  backgroundColor: '#F8F8F8',
                  borderRadius: '4px',
                  padding: '8px',
                  marginLeft: '1%',
                  border: 'none',
                  '&:hover': {
                    backgroundColor: '#E8E8E8',
                  },
                  '&:focus': {
                    outline: 'none',
                    backgroundColor: '#F0F0F0',
                  },
                }}
                IconComponent={
                  ArrowDropDown
                }
              >
                <MenuItem value={null}>Tous les types</MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{
                  ml: "1%",
                  width: '10em'
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale='frFR'
                >
                  <DatePicker
                    label="Après cette date"
                    showTodayButton
                    focused
                    autoFocus
                    clearable
                    inputFormat='DD/MM/YYYY'
                    dateFormat='DD/MM/YYYY'
                    InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                    onChange={(date) => {
                      if (dayjs(date).isValid()) {
                        const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                        setStartDate(value);
                      }
                    }}
                    renderInput={(params) =>
                      <TextField
                      {...params}
                      error={false}
                      />
                    }
                    value={dayjs(startDate, 'DD/MM/YYYY').format('MM/DD/YYYY')}
                    />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  ml: "1%",
                  width: '10em'
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale='frFR'
                >
                  <DatePicker
                    label="Avant cette date"
                    showTodayButton
                    focused
                    autoFocus
                    clearable
                    inputFormat='DD/MM/YYYY'
                    dateFormat='DD/MM/YYYY'
                    InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                    onChange={(date) => {
                      if (dayjs(date).isValid()) {
                        const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                        setEndDate(value);
                      }
                    }}
                    renderInput={(params) =>
                      <TextField
                      {...params}
                      error={false}
                      />
                    }
                    value={dayjs(endDate, 'DD/MM/YYYY').format('MM/DD/YYYY')}
                    />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  ml: "1%",
                }}
              >
                <Select
                  value={sortOrder}
                  onChange={(event) => setSortOrder(event.target.value)}
                >
                  <MenuItem value="desc">Date la plus proche</MenuItem>
                  <MenuItem value="asc">Date la plus éloignée</MenuItem>
                </Select>
              </Box>
              <Box
                sx={{
                  ml: "1%",
                }}
              >
                {/* <Typography sx={{maxWidth: '8em', fontSize: 15}}>
                  Activités de l'apprenant
                </Typography> */}
                <Checkbox
                  // sx={{ml: 5}}
                  checked={courseOnly}
                  onChange={(event) => setCourseOnly(event.target.checked)}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: '1%',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilters}
                >
                  Réinitialiser les filtres
                </Button>
              </Box>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell align="center">
                    <Typography fontWeight='Bold' fontSize={16}>
                      Apprenant
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight='Bold' fontSize={16}>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight='Bold' fontSize={16}>
                      Description
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight='Bold' fontSize={16}>
                      Date de création
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight='Bold' fontSize={16}>
                      ID du cours
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedLogs
                .map((log) =>(
                  <TableRow key={log.id}>
                    <TableCell width="200" align='center'>
                      <Typography fontSize={16}>
                        {log.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="100" align="center">
                      <SeverityPill
                        color={log.color_type}
                      >
                        {log.type}
                      </SeverityPill>
                    </TableCell>
                    <TableCell width="500" align="center">
                      <div dangerouslySetInnerHTML={{ __html: log.description }} />
                    </TableCell>
                    <TableCell width="150" align="center">
                      {log.created_at}
                    </TableCell>
                    <TableCell width="150" align="center">
                      {log.courses_id}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredLogs.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagin}
              labelRowsPerPage='Lignes par page :'
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};


export default Activities;
