class TopicsApi {
    async getAll(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/getAll/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getPostsByTopic(admin_id, topics_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/getPosts/`
        const values = {
            "admin_id": admin_id,
            "topics_id": topics_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getRepliesByPost(admin_id, posts_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/getReplies/`
        const values = {
            "admin_id": admin_id,
            "posts_id": posts_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getProgression(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/session/progression/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async createPost(admin_id, topics_id, post_body) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/createPost/`
        const values = {
            "admin_id": admin_id,
            "topics_id": topics_id,
            "post_body": post_body
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async createReply(admin_id, topics_id, posts_id, reply_body) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/createReply/`
        const values = {
            "admin_id": admin_id,
            "topics_id": topics_id,
            "posts_id": posts_id,
            "reply_body": reply_body
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deleteTopic(admin_id, topics_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/deleteTopic/`;
        const values = {
            "admin_id": admin_id,
            "topics_id": topics_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async reactivateTopic(admin_id, topics_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/reactivateTopic/`;
        const values = {
            "admin_id": admin_id,
            "topics_id": topics_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deletePost(admin_id, posts_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/deletePost/`;
        const values = {
            "admin_id": admin_id,
            "posts_id": posts_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async reactivatePost(admin_id, posts_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/reactivatePost/`;
        const values = {
            "admin_id": admin_id,
            "posts_id": posts_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deleteReply(admin_id, reply_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/deleteReply/`;
        const values = {
            "admin_id": admin_id,
            "reply_id": reply_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async reactivateReply(admin_id, reply_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/reactivateReply/`;
        const values = {
            "admin_id": admin_id,
            "reply_id": reply_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const topicsApi = new TopicsApi();