import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
  Divider,
  TextField
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { Scrollbar } from '../../scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { auditsApi } from '../../../api/audit-api';
import { useAuth } from '../../../hooks/use-auth';
import { useNavigate } from "react-router-dom";

export const AuditsList = (props) => {
  const { audits } = props;
  const { user } = useAuth();
  const [filteredAudits, setFilteredAudits] = useState([])
  const [searchName, setSearchName] = useState([])
  const [createBool, setCreateBool] = useState(false)
  const [createAuditName, setCreateAuditName] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredAudits(audits)
  }, [audits])

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setFilteredAudits(audits.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  const handleCreateAudit = async event => {
    const id = await auditsApi.createAudit(user.admin_id, createAuditName, "audit");
    navigate(`/dashboard/audits/${id.id}`)
  }

  return (
    <>
      <Box
        sx={{
          m: 1,
          width: '95%',
          display: 'flex',
          justifyContent: "flex-end",
        }}
      >
        { (createBool === false ?
          <Button
            onClick={() => {
              setCreateBool(true)
            }}
            variant='contained'
          >
            Créer un audit
          </Button>: 
        
          <Box>
            <TextField
              onChange={(event) => {
                setCreateAuditName(event.target.value)
              }}
              sx={{
                backgroundColor:'white',
                // borderRadius: 50
              }}
              label="Entrez le nom de l'audit"
            />
            <Button
              sx={{
                m:1
              }}
              variant='contained'
              onClick={() => {
                setCreateBool(false)
              }}
            >
              Annuler
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                handleCreateAudit()
              }}
            >
              Créer
            </Button>
          </Box>
        )}

      </Box>
      <Card {...props}>
        <Scrollbar>
          <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2.5
              }}
          >
            <SearchIcon fontSize="small" />
            <Box
              sx={{
                flexGrow: 1,
                ml: 3
              }}
            >
              <Input
                color='secondary'
                disableUnderline
                fullWidth
                value={searchName}
                onChange={handleChangeSearch}
                placeholder="Chercher un audit"
              />
            </Box>
          </Box>
          <Divider />
          <Table sx={{ minWidth: 700 }}>
            <TableBody>
              {audits ? filteredAudits.map((audit, index) => (
                <TableRow
                  hover
                  key={index}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        '& > img': {
                          flexShrink: 0,
                          height: 56,
                          width: 56
                        }
                      }}
                    >
                      {audit.image_link
                        ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              backgroundImage: `url(${audit.image_link})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              borderRadius: 1,
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              overflow: 'hidden',
                              width: 100
                            }}
                          />
                        )
                        : (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              borderRadius: 1,
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              width: 100
                            }}
                          >
                            <ImageIcon fontSize="small" />
                          </Box>
                        )}
                      <Box sx={{ ml: 3 }}>
                        <IconButton
                          component = {RouterLink}
                          to={`/dashboard/audits/${audit.id}`}
                        >
                          <Typography variant="h6"
                            color='primary'
                          >
                            {audit.name}
                          </Typography>
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )) : NULL}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  )
};
