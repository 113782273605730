import { Box, Button, Card, Container, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { facturationApi } from '../../../api/facturation-api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

const Facturation = () => {

  const [data, setData] = useState({
    "email": "",
    "formation_tlms_id": "",
    "end_session": ""
  });

  const handleLogs = async (logs) => {
    try {
      const tableExcel = [];
        const units = logs.data.units.filter((el) => typeof (el.length) === 'undefined');
        console.log(logs.data.units[0]);
        units.forEach((element) => {
          tableExcel.push(['Nom', 'Prénom', 'Email du participant', 'ID de la session', 'Nom du module', 'Connexion - Date et heure', 'Déconnexion - Date et heure', 'Temps passé', 'Progression (%)']);
          element.codeco.forEach((cdc) => tableExcel.push([`${logs.data.lastname}`, `${logs.data.firstname}`, `${logs.data.email}`, `${logs.data.program_number}`, `${cdc.module_name}`, `${cdc.connect}`, `${cdc.disconnect}`, `${cdc.duration}`, `${cdc.progression}`]));
          tableExcel.push(['', '', '', '', `${element.expected_time}`, '', '', `${element.total_time}`]);
          tableExcel.push([]);
        });
      // tableExcel.push(["Date d'inscription", `${logs.data.subscribed_at}`]);
      const worksheet = XLSX.utils.aoa_to_sheet(
        tableExcel
      );

      const colNum = XLSX.utils.decode_col('H');
      const fmt = 'h:mm:ss';

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        if (worksheet[ref] && worksheet[ref].v.includes(':')) {
          worksheet[ref].z = fmt;
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Logs');

      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, `User ${logs.data.lastname} ${logs.data.firstname}.xlsx`);
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la génération de l\'attestation !');
    }
  };

  function handleModify(field, value) {
    setData(data => ({
      ...data,
      [field]: value})
    );
  }

  function checkObjectNotEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (!obj[prop]) {
          return false;
        }
      }
    }
    return true;
  }

  async function handleSubmit() {
    if (checkObjectNotEmpty(data)) {
      const endSession = dayjs(data["end_session"], 'DD/MM/YYYY').format('YYYY-MM-DD');
      const result = await facturationApi.getLogs(data["email"], data["formation_tlms_id"], endSession);
      if(result["msg"]) {
        toast.error("La génération a échouée", {
          style: {
            minWidth: '20em',
            height: '4em',
            fontSize: '130%',
            fontWeight: 'bold'
          },
        })
      } else {
        handleLogs(result);
        toast.success("Fichier de log généré!", {
          style: {
            minWidth: '15em',
            height: '4em',
            fontSize: '130%',
            fontWeight: 'bold'
          },
        })
      }
    } else {
      toast.error("Veuillez remplir tous les champs!", {
        style: {
          minWidth: '20em',
          height: '4em',
          fontSize: '130%',
          fontWeight: 'bold'
        },
      })
    }
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4">
                  Facturation
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Box
              sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 3
              }}
            >
                <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                >
                    Email de la personne
                </Typography>
                <TextField
                  sx={{
                    ml: "2em",
                    minWidth: "22em"
                  }}
                  size='small'
                  onChange={(e) => handleModify("email", e.target.value)}
                />
            </Box>
            <Box
              sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 3,
              pt: 1
              }}
            >
                <Typography
                    sx={{ minWidth: 180}}
                    variant="subtitle2"
                >
                    Id de la formation sur TLMS
                </Typography>
                <TextField
                  sx={{
                    ml: "2em",
                    maxWidth: "4em"
                  }}
                  size='small'
                  onChange={(e) => handleModify("formation_tlms_id", e.target.value)}
                />
            </Box>
            <Box
              sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 3,
              pt: 1
              }}
            >
              <Typography
                  sx={{ minWidth: 180}}
                  variant="subtitle2"
              >
                  Date de fin de session
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale='frFR'
              >
              <DatePicker
                label="Entrez une date"
                showTodayButton
                focused
                autoFocus
                size='small'
                value={dayjs(data["end_session"], 'DD/MM/YYYY').format('MM/DD/YYYY')}
                clearable
                inputFormat='DD/MM/YYYY'
                dateFormat='dd/MM/YYYY'
                onChange={(date) => {
                  if (dayjs(date).isValid()) {
                    const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                    handleModify("end_session", value);
                  }
                }}
                renderInput={(params) => <TextField sx={{ ml: "2em", maxWidth: '11em'}} size='small'{...params} />}
              />
            </LocalizationProvider>
            </Box>
            <Button
                sx={{
                  m: 2,
                  ml: "1em",
                  minWidth: "7em",
                  height: "3em"
                }}
              onClick={handleSubmit}
              variant='contained'
            >
              Générer la facturation
            </Button>
          </Card>
        </Container>
    </Box>
  </>
);
};

export default Facturation;
