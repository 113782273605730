import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';
import { Scrollbar } from '../../../components/scrollbar';
import { useMounted } from '../../../hooks/use-mounted';
import { userApi } from '../../../api/user-api';
import SearchIcon from '@mui/icons-material/Search';

const Customers = (props) => {
  const isMounted = useMounted();
  const {
    customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [searchName, setSearchName] = useState([])
  const [sort, setSort] = useState('');
  const [pagin, setPagin] = useState(0);
  const [limit, setLimit] = useState(25);

  const { user } = useAuth(); 

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setFilteredCustomers(customers.filter((el) => `${el.firstname} ${el.lastname}`.toLowerCase().includes(event.target.value.toLowerCase()) || `${el.lastname} ${el.firstname} `.toLowerCase().includes(event.target.value.toLowerCase()) || `${el.email}`.toLowerCase().includes(event.target.value.toLowerCase())))
    setPagin(0);
  }

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPagin(newPage)
  }


  const getCustomers = useCallback(async () => {
    try {
      const data = await userApi.getUsers(user.admin_id);
      if (isMounted()) {
        setCustomers(data);
        setFilteredCustomers(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const applyPagination = (sessions, pagin, limit) => sessions
  .slice(pagin * limit, pagin * limit + limit);

  const paginatedCustomers = applyPagination(filteredCustomers, pagin, limit);

  useEffect(() => {
    getCustomers();
  },
  []);

  useEffect(() => {
      if (selectedCustomers.length) {
        setSelectedCustomers([]);
      }
    },
    [customers]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
              <Grid
                container
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item>
                  <Typography variant="h4">
                    Apprenants
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        <Card>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 3
            }}
          >
            <Box
              sx={{
                m: 1,
                maxWidth: '100%',
                width: 450
              }}
            >
              <TextField
                value={searchName}
                onChange={handleChangeSearch}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                placeholder="Chercher un apprenant"
              />
            </Box>
          </Box>
          <Scrollbar>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 2}}>
                    <Typography
                      sx={{ mx: 3}}
                      fontWeight='Bold'
                      fontSize={13}
                    >
                      Nom
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ p: 2}}>
                    <Typography
                      sx={{ mx: 3}}
                      fontWeight='Bold'
                      fontSize={13}
                    >
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ p: 2}}>
                    <Typography
                      sx={{ mx: 3}}
                      fontWeight='Bold'
                      fontSize={13}
                    >
                      Tlms
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ p: 2}}>
                    <Typography
                      sx={{ mx: 3}}
                      fontWeight='Bold'
                      fontSize={13}
                    >
                      Syna
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedCustomers.map((customer) => (
                  <TableRow
                    hover
                    key={customer.id}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 2 }}>
                          <RouterLink
                            to={`/dashboard/customers/${customer.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <Typography
                              color={(theme) => theme.palette.mode === 'dark' ? process.env.REACT_APP_SECONDARY_COLOR : process.env.REACT_APP_PRIMARY_COLOR}
                              fontWeight='Bold'
                              fontSize={14}
                            >
                              {customer.firstname} {customer.lastname}
                            </Typography>
                          </RouterLink>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          // p: 0,
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                            fontSize={15}
                          >
                            {customer.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 3}}>
                          {customer.tlms_id ?
                            <IconButton
                              href={`${process.env.REACT_APP_TLMS_URL}/user/info/id:${customer.tlms_id}`}
                              target="_blank"
                            >
                              <LinkIcon fontSize="small" />
                            </IconButton>
                          : " "}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 3 }}>
                      </Box>
                      <IconButton
                        href={`${process.env.REACT_APP_SYNA_URL}/dashboard/customers/${customer.syna_id}`}
                        target="_blank"
                      >
                        <LinkIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePagination
            component="div"
            count={filteredCustomers.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={pagin}
            labelRowsPerPage='Lignes par page :'
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Card>
        </Container>
    </Box>
  </>
);
};

export default Customers;
