import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { SeverityPill } from '../../../components/severity-pill';
import { gtm } from '../../../lib/gtm';

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import { Scrollbar } from '../../../components/scrollbar';
import { useMounted } from '../../../hooks/use-mounted';
import SearchIcon from '@mui/icons-material/Search';
import { sessionsApi } from '../../../api/session-api';

import { Link as RouterLink } from 'react-router-dom';
import { auditsApi } from '../../../api/audit-api';

const sortOptions = [
  {
    label: 'Fin de session plus proche',
    value: 'end_session_order|asc'
  },
  {
    label: 'Fin de session plus éloignée',
    value: 'end_session_order|desc'
  }
];

const SessionHome = () => {
  const isMounted = useMounted();
  const [sessions, setSessions] = useState([])
  const [audits, setAudits] = useState([])
  const [auditsSearch, setAuditsSearch] = useState("Tous")
  const [filteredSessions, setFilteredSessions] = useState([])
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [searchName, setSearchName] = useState([])
  const [sort, setSort] = useState('end_session_order|desc');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const { user } = useAuth(); 

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setAuditsSearch('Tous')
    setFilteredSessions(sessions.filter((el) => `${el.audit_name}`.toLowerCase().includes(event.target.value.toLowerCase())))
    setPage(0);
  }

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleAuditChange = (event) => {
    if (event.target.value === 'Tous') {
      setFilteredSessions(sessions.filter((el) => `${el.audit_name}`.toLowerCase()))
      setAuditsSearch('Tous')
    }
    else {
      setFilteredSessions(sessions.filter((el) => `${el.audit_name}`.toLowerCase().includes(event.target.value.toLowerCase())))
      setAuditsSearch(event.target.value)
    }
  }
  const getAudits = useCallback(async () => {
    try {
      const data = await auditsApi.getAllEpp(user.admin_id)
      if (isMounted()) {
        data.unshift({"id": "0", "name": "Tous"})
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getSessions = useCallback(async () => {
    try {
      const data = await sessionsApi.getAllSessions(user.admin_id);
      if (isMounted()) {
        setSessions(data);
        setFilteredSessions(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => (order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy));

  const applySort = (customers, sort) => {
    const [orderBy, order] = sort.split('|');
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = customers.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) {
        return newOrder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const applyPagination = (sessions, page, limit) => sessions
  .slice(page * limit, page * limit + limit);

  const sortedSessions = applySort(filteredSessions, sort);
  const paginatedSessions = applyPagination(sortedSessions, page, limit);

  useEffect(() => {
    getAudits();
    getSessions();
    gtm.push({ event: 'page_view' });
  },
  []);

  useEffect(() => {
      if (selectedAudits.length) {
        setSelectedAudits([]);
      }
    },
    [sessions]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
          
        }}
        // style={{ backgroundImage: `linear-gradient(to right, rgba(0, 224, 255, 1), rgba(0, 133, 255, 1))`, }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
              <Grid
                container
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item>
                  <Typography 
                    sx={{
                    color:(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : "#1c2531"
                    }}
                    variant="h4"
                  >
                    Liste des sessions
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        <Card>
          
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 3
            }}
          >
            <Box
              sx={{
                m: 1,
                maxWidth: '100%',
                width: 450
              }}
            >
              <TextField
                value={searchName}
                onChange={handleChangeSearch}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                placeholder="Chercher un audit"
              />
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240
              }}
            >
              <TextField
                label="Trier par"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={sort}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {sortOptions.map((option) => (
                  <option
                    key={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240
              }}
            >
              <TextField
                  label="Choisir un audit"
                  name="choose"
                  onChange={handleAuditChange}
                  select
                  SelectProps={{ native: true }}
                  value={auditsSearch}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                {audits.map((option) => (
                  <option
                    key={option.id}
                    value={option.name}
                  >
                    {option.name}
                  </option>
                  ))}
                </TextField>
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240,
                display: 'flex',
                justifyContent: "flex-end",
              }}
            >
              <RouterLink
                to={`/dashboard/session/create`}
                state={{
                  from: "/dashboard/session",
                  formationId: -1
                }}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                >
                  Créer une session
                </Button>
              </RouterLink>
            </Box>
          </Box>
          <Scrollbar>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 2 }}>
                    <Typography
                       sx={{ mx: 3 }}
                       
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Nom de l&apos;audit
                    </Typography>
                  </TableCell>
                  <TableCell >
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Début de session
                    </Typography>
                    
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Fin de session
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Pas commencé
                    </Typography>
                    
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      En cours
                    </Typography>
                    
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Terminé
                    </Typography>
                    
                  </TableCell>
                  <TableCell>
                     <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Total
                    </Typography>
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedSessions ? paginatedSessions.map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      backgroundColor:item.sessions_id? '#EFF7FF' : ""
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Box sx={{ ml: 2 }}>
                          <RouterLink
                            to={`/dashboard/session/${index + 1}`}
                            state={{
                              data_from: {
                                auditId: item.audit_id,
                                startSession: item.start_session,
                                endSession: item.end_session,
                                auditName: item.audit_name,
                                sessions_id: item.sessions_id,
                                sessions_number: item.sessions_number,
                                from: "/dashboard/session"
                              }
                            }}
                            style={{ textDecoration: 'none' }}
                          >
                            <Typography
                              color={(theme) => theme.palette.mode === 'dark' ? process.env.REACT_APP_SECONDARY_COLOR : process.env.REACT_APP_PRIMARY_COLOR}
                              fontWeight='Bold'
                              fontSize={15}
                              // variant="body1"
                            >
                              {item.audit_name}
                            </Typography>
                            {item.sessions_id ? 
                              <Typography
                                // color={(theme) => theme.palette.mode === 'dark' ? process.env.REACT_APP_SECONDARY_COLOR : process.env.REACT_APP_PRIMARY_COLOR}
                                color="secondary"
                                fontWeight='Bold'
                                fontSize={14}
                              >
                                Session n° {item.sessions_number}
                              </Typography>
                              :
                              ""
                            }
                          </RouterLink>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          p: 2,
                          // variant:"body1"
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                            fontSize={18}
                          >
                            {item.start_session}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                            fontSize={18}
                          >
                            {item.end_session}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                         
                  
                            <Typography
                            // color="textSecondary"
                            color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                            variant="h6"
                  >
                      {(Math.round(item.pas_commence_percent) + "% (" + item.pas_commence + ")")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                            variant="h6"
                          >
                      {(item.en_cours_percent + "% (" + item.en_cours + ")")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography
                           color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                           variant="h6"
                          >
                             {item.termine_percent + "% (" + item.termine + ")"}
                          </Typography>
                         
                        </Box>
                      </Box>
                    </TableCell>
                    
                    <TableCell>
                      <Box>
                        <Box sx={{ ml: 1 }}
              >
                          <Typography
                            // color="textSecondary"
                            color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                            fontWeight='Bold'
                            variant="h6"
                          >
                            <SeverityPill>
                            {item.total}
                          </SeverityPill>
                          </Typography>
                          
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) : NULL}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePagination
            component="div"
            count={filteredSessions.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            labelRowsPerPage='Lignes par page :'
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
        </Container>
    </Box>
  </>
);
};

export default SessionHome;
