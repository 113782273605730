import { Box, Card, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { tcsApi } from "../../../api/tcs-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { VignetteStats } from "./tcs-vignetteStats";
import { ExportTcsStats } from "./tcs-export-stats";

export const TcsStatistiques = (props) => {
  const { tcsId } = props;
  const isMounted = useMounted();
  const [tcsStats, setTcsStats] = useState([]);
  const [cronbach, setCronbach] = useState([]);
  const [loadingStats, setLoadingStats] = useState(true);

  const { user } = useAuth(); 

  const getTcsStats = useCallback(async () => {
    try {
      setLoadingStats(true)
      const data = await tcsApi.getResultsBySession(user.admin_id, tcsId)
      const stats = await tcsApi.getCronbach(user.admin_id, tcsId)
      if (isMounted()) {
        setTcsStats(data);
        setCronbach({ 'alpha': stats.alpha, 'alpha_min': stats.alpha_min, 'alpha_max': stats.alpha_max })
        setLoadingStats(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  // const getCronbach = useCallback(async () => {
  //   try {
  //     const data = await tcsApi.getCronbach(user.admin_id, tcsId)
  //     if (isMounted()) {
  //       setCronbach(data)
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMounted]);

  useEffect(() => {
    getTcsStats();
    // getCronbach();
  }, []);

  return (
    <Box sx={{mb: 15}}>
      {!loadingStats && (
      <Card sx={{ p: 1, mt: 3, mb: 1 }}>
          <Typography sx={{ ml: 1 }}><span style={{ fontSize: 18, fontWeight: 'bold' }}>Alpha </span>{cronbach.alpha}</Typography>
          <Typography sx={{ ml: 1 }}><span style={{ fontSize: 18, fontWeight: 'bold' }}>Alpha min. </span>{cronbach.alpha_min}</Typography>
          <Typography sx={{ ml: 1 }}><span style={{ fontSize: 18, fontWeight: 'bold' }}>Alpha max. </span>{cronbach.alpha_max}</Typography>
      </Card>)}
      {!loadingStats ?
        tcsStats.sessions.map((session, index) => (
          <Card index={index} sx={{mb: 4}}>
            <Grid
              container
              justifyContent={'flex-end'}
              paddingRight={3}
              paddingTop={2}
            >
              <ExportTcsStats tcsId={tcsId} session={session} global={tcsStats.global}/>
            </Grid>
            <Grid
              container
              justifyContent={'flex-end'}
              // marginTop={3}
            >
              <Typography fontSize={20}>
                Début de session :
              </Typography>
              <Typography fontSize={20} sx={{ml:0.5, mr: 2}} fontWeight={"bold"}>
                {session.global_data.start_session}
              </Typography>
              <Typography fontSize={20}>
                Fin de session :
              </Typography>
              <Typography fontSize={20} sx={{ml:0.5, mr:4}} fontWeight={"bold"}>
                {session.global_data.end_session}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent={'flex-end'}
              marginTop={2}
            >
              <Typography
                fontSize={20}
              >
                Temps moyen passé par session :&nbsp;
              </Typography>
              <Typography
                fontSize={20}
                fontWeight={"bold"}
                sx={{
                  mr:4
                }}
              >
                {session.global_data.time_spent}
              </Typography>
            </Grid>
            <Typography
              variant="h6"
              sx={{ m: 2}}
            >
              Alpha : {session.global_data.alpha}
            </Typography>
            <Typography
              variant="h6"
              sx={{ m: 2}}
            >
              Nombre d'apprenant(s) dans la session : {session.global_data.learner_number}
            </Typography>
            {session.turn_1.result.length > 0?
              <Grid
                container
                padding={2}
                sx={{mt: 2}}
              >
                <Grid
                marginLeft={11}
                width={'40%'}
                >
                  <VignetteStats result={session.turn_1.result} global={tcsStats.global.turn_1} turn={1}/>
                </Grid>
                <Grid
                marginLeft={15}
                width={'40%'}
                >
                  <VignetteStats result={session.turn_2.result} global={tcsStats.global.turn_2} turn={2}/>
                </Grid>
              </Grid>
            :
              <Typography sx={{ml: '2em', p:3}}>
                Aucune statistique pour le moment
              </Typography>
            }
          </Card>
        ))
      :
        <CircularProgress/>
      }
    </Box>
  );
};