import ArticleIcon from '@mui/icons-material/Article';
import { AdfScanner, FlakyOutlined } from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import ListIcon from '@mui/icons-material/List';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CogIcon from '../../icons/Cog';

export const getSections = (t) => [
    {
      title: 'Analyses',
      items: [
        {
          title: t('Tableau de bord'),
          path: '/dashboard',
          icon: <HomeIcon fontSize="small" />
        },
      ]
    },
    {
      title: 'Commercial',
      items: [
        {
          title: t('Apprenants'),
          path: '/dashboard/customers',
          icon: <GroupIcon fontSize="small" />,
        },
        {
          title: t('Créer un apprenant'),
          path: '/dashboard/create',
          icon: <ControlPointIcon fontSize="small" />,
        },
      ]
    },
    {
      title: 'Évaluations',
      items: [
        {
          title: t('Formations'),
          path: '/dashboard/formations',
          icon: <ArticleIcon fontSize="small" />,
        },
        {
          title: t('Audits'),
          path: '/dashboard/audits',
          icon: <VerifiedOutlinedIcon fontSize="small" />,
        },
        {
          title: t('TCS'),
          path: '/dashboard/tcs',
          icon: <FlakyOutlined fontSize="small" />,
        },
      ]
    },
    {
      title: 'Sessions',
      items: [
        {
          title: t('Liste des sessions'),
          path: '/dashboard/session',
          icon: <ListIcon fontSize="small" />
        }
      ]
    },
    {
      title: 'Autres',
      items: [
        {
          title: t('Activités'),
          path: '/dashboard/activities',
          icon: <TrendingUpIcon fontSize='small'/>,
        },
        {
          title: t('Erreurs'),
          path: '/dashboard/errors',
          icon: <WarningRoundedIcon fontSize="small" />,
        },
        {
          title: t('Forum'),
          path: '/dashboard/forum',
          icon: <ForumIcon fontSize="small" />
        },
        {
          title: t('Facturation'),
          path: '/dashboard/facturation',
          icon: <AdfScanner fontSize="small" />,
        },
        {
          title: t('Paramètres'),
          path: '/dashboard/params',
          icon: <CogIcon fontSize="small" />,
        },
      ]
    }
];