import { useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/use-auth';

import {
    Box,
    Card,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

import { Link as RouterLink} from 'react-router-dom'
import { SeverityPill } from '../../severity-pill';
import { makeStyles } from '@material-ui/styles';
import { userApi } from '../../../api/user-api';
import { useMounted } from '../../../hooks/use-mounted';

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "4.5px 0px 6px 16px"
    }
  },
});

export const LastActivities = (props) => {
  const { user } = useAuth(); 

  const isMounted = useMounted();
  const [logs, setLogs] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function getLogs() {
      try {
        const data = await userApi.getLastActivities(user.admin_id);
        if (isMounted()) {
          setLogs(data);
        }
      } catch (err) {
        console.error(err);
      }
      
    };
    getLogs();
    const interval = setInterval(() => getLogs(), 10000)
    return () => {
      clearInterval(interval)
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Grid>
            <Typography
              variant="h4"
            >
              Dernières activités
            </Typography>
          </Grid>
        }
      />
      <Box>
      <TableContainer>
        <Table
          classes={{root: classes.customTable}}
          size="small"
        >
          <TableHead>
            <TableRow>
            <TableCell>
                <Typography
                  fontWeight='Bold'
                  fontSize={16}
                >
                  Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  fontWeight='Bold'
                  fontSize={16}
                >
                  Activité
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index) => (
              <TableRow
                hover
                key={index}
              >
                <TableCell
                  width={150}
                >
                  <SeverityPill
                    color={log.color_type}
                  >
                    {log.type}
                  </SeverityPill>
                </TableCell>
                <TableCell>
                  <RouterLink
                    to={'/dashboard/customers/' + log.user_id}
                    style={{textDecoration: 'none'}}
                    state={{
                      from: '',
                      data_from: {
                      }
                    }}
                  >
                    <Typography
                      display={'inline'}
                      color={'primary'}
                      fontSize={14}
                      fontWeight={'bold'}
                      >
                      {log.name}&nbsp;
                    </Typography>
                  </RouterLink>
                  <Tooltip title={log.activity}>
                    <Typography
                      display={'inline'}
                      color={'primary'}
                      fontSize={14}
                    >
                      {log.activity.length + log.name.length > 80 ? log.activity.substring(0, 80 - log.name.length) + "..." : log.activity}
                    </Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};
