import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/use-auth';
import { gtm } from '../../lib/gtm';

import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';

import { useMounted } from '../../hooks/use-mounted';
import { topicsApi } from '../../api/topic-api';
import { auditsApi } from '../../api/audit-api';

import { AvancementSessions } from '../../components/dashboard/statistiques/avancementSessions';

import { MeanByAudit } from '../../components/dashboard/statistiques/mean-audit';
import { LastActivities } from '../../components/dashboard/statistiques/last-activities';

const Dashboard = () => {
  const isMounted = useMounted();
  const [progression, setProgression] = useState([])
  const [meanTime, setMeanTime] = useState([])

  const { user } = useAuth(); 

  const getMeanTime = useCallback(async () => {
    try {
      const data = await auditsApi.meanTime(user.admin_id)
      if (isMounted()) {
        setMeanTime(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getProgression = useCallback(async () => {
    try {
      const data = await topicsApi.getProgression(user.admin_id)
      if (isMounted()) {
        setProgression(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getProgression();
    getMeanTime();
    gtm.push({ event: 'page_view' });
  },
  []);


  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
          
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography 
                  sx={{
                    color:(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : "#1c2531"
                  }}
                  variant="h4"
                >
                  Tableau de bord
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container 
            spacing={2}
          >
            <Grid item 
              sm={12}
              md={12}
              lg={6}
            >
              <AvancementSessions 
                progression={progression}
              />
            </Grid>
            <Grid item 
              sm={12}
              md={12} 
              lg={6}
            >
              <LastActivities />
            </Grid>
          </Grid>
          <Box
            marginTop={5}
          >
            <MeanByAudit
              time={meanTime}
              />
          </Box>
        </Container>
    </Box>
  </>
);
};

export default Dashboard;
