class ParamsApi {
    async getParamAutomaticMail(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/config/automaticMail/get/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async setParamAutomaticMail(admin_id, isAutomaticMail) {
        const url = `${process.env.REACT_APP_BACK}/api/config/automaticMail/set/`
        const values = {
            "admin_id": admin_id,
            "is_automatic_mail": isAutomaticMail,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getParamAutomaticMailToCustomer(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/config/automaticMailToCustomer/get/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async setParamAutomaticMailToCustomer(admin_id, isAutomaticMail) {
        const url = `${process.env.REACT_APP_BACK}/api/config/automaticMailToCustomer/set/`
        const values = {
            "admin_id": admin_id,
            "is_automatic_mail_to_customer": isAutomaticMail,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    
}
export const paramsApi = new ParamsApi();